/* Products Module Custom Styles */

div#breadcrumbs {
    margin-bottom: 1.5rem;
}

.product-card-cont {
    margin-bottom: 2rem;
}

.product-card {
    border: 1px solid transparent;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;
}

.product-card > a {
    overflow: hidden;
    display: block;
}

.product-card .product-img-wrapper {
    overflow: hidden;
}

.product-card img {
    width: 100%;
    height: auto;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
}

.product-card .product-label a {
    &:first-of-type {
        display: inline-block;
        width: 58%;
        padding-left: 20px;
        padding-top: 12px;
        font-weight: bolder;
        color: #727272;
    }
    &:last-of-type {
        display: inline-block;
        width: 40%;
        text-align: right;
        color: #f31717;
        padding-top: 12px;
        padding-right: 20px;
    }
}

.product-card:hover {
    .product-label a {
        &:first-of-type {
            color: darken(#727272, 10%);
        }
        &:last-of-type {
            color: darken(#f31717, 10%);
        }
    }
}

.product-card:hover img {
    transform: scale(1.1);
    opacity: 0.3s;
}

ul.thumbs {
    list-style-type: none;
    margin: 0px;
}

.product-left .owl-dots {
    text-align: center;
}

.product-left .owl-dot {
    height: 8px;
    width: 8px;
    background-color: rgba(34, 39, 40, 0.4);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
}

.product-left .owl-dot:hover,
.product-left .owl-dot.active {
    background-color: $brand;
}

.product-left .owl-pagination {
    text-align: center;
}