/*
    GloverSure Foundation Template - CSS in this file is divided into sections: Header, Slideshow etc.

    Styles for other moduleg are split into individual SCSS files in the 'Modules directory'.
*/

/* ===========================] General [======================== */

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600|Roboto:400,500,700');

$f-title: 'Roboto', sans-serif;
$f-main: 'Poppins', sans-serif;

@mixin button {
    background-color: $brand;
    border-radius: 4px;
    color: #fff;
    padding: 12px 30px;
    display: inline-block;
    margin-top: 0.6em;
}
@mixin button-l {
    border: solid 1px #fff;
    border-radius: 4px;
    color: #fff;
    padding: 12px 30px;
    display: inline-block;
    margin-top: 0.6em;
}

body, html{
    background-color: #fff;
    font-family: $f-main;
    color: #727272;
    font-weight: lighter;
    line-height: 2;
}

::selection{
    background: $brand;
    color: #fff;
}

h1, h2, h3, h4, h5, h6{
    font-family: $f-title;
    font-weight: bold;
    color: $title;
}

p, ul{
    color: #727272;
    font-weight: lighter;
    line-height: 2;
}

.grid-x {
    width: 90%;
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-flow: row wrap;
    &.expanded{
        max-width: 100%;
        width: 100%;
    }
    &.full{
        max-width: 100%;
        width: 100%;
    }

}

.g-recaptcha {
    margin-bottom: 1em;
}

.scrollup {
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: none;
	background-color: #FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	color: #0A0A0A;
	line-height: 40px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}

#cookie-notice{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $brand;
    text-align: center;
    padding: 1em;
    color: #fff;
    z-index: 999;
    p{
        margin-bottom: 10px;
        font-size: 0.9em;
    }
    a{
        background-color: darken($brand, 10%);
        padding: 0.7em 1em;
        display: inline-block;
        font-size: 0.8em;
        border-radius: 2px;
        color: #fff;
    }
}

.input-group {
	display: block;
}

.cc-banner .cc-message,
.cc-compliance>.cc-btn {
    font-family: $f-main;
    font-size: 13px;
    font-weight: 400;
}

/* ===========================] End General [======================== */



/* ===========================] Header [======================== */

header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 20px 60px;
    z-index: 3;
    transition: 0.4s;
    &.header-scrolled{ /* Styles for header when scrolled here, logo shrink etc */
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    }
    @media screen and (max-width: 1025px) {
        padding: 0;
        padding-right: 15px;
    }
}

.header-left {
    transition: 0.4s;
    .logo{
        float: left;
    }
}
.header-mid{
    .menu{
        li{
            a{
                color: #0e0e0e;
                transition: 0.2s;
                &:hover{
                    color: $brand;
                }
                @media screen and (max-width: 1025px) {
                    color: #fff;
                    text-align: left;
                    &:hover{
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .menu-icon::after {
    	background: $brand;
    	box-shadow: 0 7px 0 $brand, 0 14px 0 $brand;
    }
}
.header-right {
    @media screen and (max-width: 640px) {
        display: none;
    }
    &::before{
        content: '';
        display: inline-block;
        width: 30px;
        height: 2px;
        margin-left: 10px;
        margin-right: 25px;
        background-color: #ededed;
        vertical-align: middle;
    }
    a{
        padding: 5px;
        margin: 5px;
        font-size: 1.2em;
    }
}

/* ===========================] End Header [======================== */



/* ===========================] Slideshow [======================== */

#slideshow{
    position: relative;


    .slideshow-left, .slideshow-right{
        position: relative;
        ul{
            margin: 0px;
            list-style-type: none;
            li{
                height: auto;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                &:after{
                    content: '';
                    z-index: 2;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    opacity: .7;
                    background: linear-gradient(to bottom,transparent 0,#000 100%);
                }
            }
        }
        .slideshow-overlay{
            position: absolute;
            bottom: 2em;
            left: 2em;
            z-index: 2;
            text-align: left;
            h2{
                color: #fff;
                @media screen and (max-width: 900px) {
                    font-size: 28px;
                }
            }
            a{
                @include button-l;
                @media screen and (max-width: 900px) {
                    padding: 5px 20px;
                    font-size: 14px;
                }
            }
        }

        .owl-dots {
        	position: absolute;
        	bottom: 2em;
        	right: 2em;
            text-align: center;
            .owl-dot{
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #fff;
                margin: 0px 3px;
                border-radius: 50%;
                opacity: 0.4;
                &.active{
                    opacity: 1;
                }
            }
        }

    }

    .slide {
        background-color: #000;
        img{
        opacity: 0.85;
        }
    }

    .overlay {
    	position: absolute;
    	top: 50%;
    	left: 0;
    	transform: translateY(-20%);
    	right: 0;
    	margin: 0 auto;
    	text-align: center;
        opacity: 0;
        transition: 0.5s;
        transition-delay: 0.6s;
        h1, h2, h3, h4, h5, h6, p{
            color: #fff;
        }
        &.animated{ /* Animates in the Slideshow text */
            opacity: 1;
            transform: translateY(-50%);
        }
    }
    .owl-prev, .owl-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        i{
            color: #fff;
            padding: 10px;
        }
    }
    .owl-prev{
        left: 30px;
    }
    .owl-next{
        right: 30px;
    }
}

/* ===========================] End Slideshow [======================== */

#h-intro{
    h1{
        font-size: 2em;
        margin-bottom: 16px;
    }
    h3{
        font-size: 1.25em;
        margin: 25px 0 10px;
    }
    .grid-x{
        padding: 5% 0vw;
    }
    .cell:first-of-type{
        p:last-of-type{
            margin: 0px;
        }
    }
    .quote-cta-box{
        background-color: $brand;
        padding: 3em;
        text-align: center;
        *{
            color: #fff;
        }
        h1, h2, h3, h4, h5, h6{
            font-size: 22px;
        }
        a{
            @include button;
            background-color: $brand-dark;
        }
        @media screen and (max-width: 1025px) {
            margin-top: 30px;
        }
    }
    .quote-box{
        a{
            background-color: darken($brand, 5%);
            border-radius: 4px;
            color: #fff;
            padding: 30px;
            display: block;
            text-align: center;
            margin-bottom: 25px;
            font-size: 1.5rem;
            font-weight: 600;
            transition: 0.4s;
            &:hover{
                background-color: darken($brand, 9%);
            }
        }
    }
}

#h-split{
    .grid-x{
        padding-bottom: 5%;
    }
    .cell{
        padding-right: 4%;
        @media screen and (max-width: 1025px) {
            padding: 0;
            margin-bottom: 30px;
        }
        h1, h2, h3, h4, h5, h6{
            font-size: 22px;
            margin: 1em 0em 0.6em;
        }
    }
}

#h-featured{
    .grid-x:first-of-type{
        padding-bottom: 1em;
        border-bottom: solid 1px #ededed;
        h1, h2, h3, h4, h5, h6{
            font-size: 22px;
            &::before{
                content: '\f091';
                font-family: 'FontAwesome';
                color: $brand;
                margin-right: 10px;
            }
        }
        .cell:last-of-type{
            a{
                color: #323232;
                &::after{
                    content: '→';
                    color: $brand;
                    margin-left: 12px;
                }
            }
        }
    }
    .grid-x:last-of-type{
        padding-top: 3em;
        padding-bottom: 5%;
        .cell{
            padding: 12px;
            p{
                margin: 0;
                img{
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;
                }
                &:nth-child(2){
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 75px);
                    font-weight: bolder;
                }
                &:nth-child(3){
                    display: inline-block;
                    vertical-align: middle;
                    width: 40%;
                    text-align: right;
                    color: $brand;
                    width: 70px;
                }
            }
            p:blank{
                display: none;
            }
        }
    }
}


#h-prices{
    background: url("../images/prices-bg.png");
    background-position: center center;
    background-size: cover;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 0;
    }
    .grid-x{
        padding: 8% 0%;
        position: relative;
        z-index: 1;
        min-height: 375px;
        h1, h2, h3, h4, h5, h6{
            color: #fff;
        }
        p{
            color: #fff;
        }
        a{
            @include button-l;

        }
    }
}

#contact-bar{
    background-color: $brand;
    padding: 2em 0em;
    *{
        color: #fff;
    }
    p{
        margin: 0px;
    }
    .cell:first-of-type{
        p{
            font-family: $f-title;
            font-size: 1.3em;
        }
    }
    .cell:last-of-type{
        p{
            display: inline-block;
            margin-left: 30px;
            font-size: 1.1em;
            @media screen and (max-width: 500px) {
                margin: 0;
                font-size: 15px;
                display: block;
            }
            &::before{
                color: #ba1818;
                font-family: 'FontAwesome';
                margin-right: 12px;
                font-size: 20px;
                vertical-align: middle;
            }
        }
        p:first-of-type{
            &:before{
                content: '\f095';
            }
        }
        p:last-of-type{
            &:before{
                content: '\f0e0';
            }
        }
    }
}


#map-block{
    position: relative;
    #map{
        height: 550px;
    }
    > a{
        @include button;
        position: absolute;
        bottom: 25px;
	    right: 52px;
        font-size: 13px;
    }
}

footer{
    .footer-top{
        padding: 1.5em 0em;
        .footer-menu{
            .menu{
                justify-content: flex-start !important;
                a{
                    font-weight: 500;
	                color: #1e1e1e;
                }
            }
            @media screen and (max-width: 1025px) {
                display: none;
            }
        }
        .footer-brands{
            text-align: right;
            img{
                display: inline-block;
                margin-left: 10px;
                max-width: 160px;
                @media screen and (max-width: 1025px) {
                    margin: 0 5px;
                }
            }
        }
        .brand-logos{
            @media screen and (max-width: 1025px) {
                text-align: center;
            }
        }
    }
    .footer-bottom{
        background-color: #323232;
        padding: 2em 0em;
        color: gray;
        .cell p{
            display: inline-block;
            margin: 0px 10px;
            @media screen and (max-width: 500px) {
                font-size: 14px;
                margin: 0 5px;
                display: block;
            }
            a{
                color: #fff;
            }
        }
    }
}


/* Vehicle Hire Search */


.hire-search-form{
    text-align: left;
    margin-top: 25px;
    .input-group{
        &:after{
            content: "";
            display: table;
            clear: both;
        }
    }
    label{
        display: inline-block;
        width: 175px;
        float: left;
        height: 40px;
        line-height: 40px;
        
        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }
    select,
    input{
        display: inline-block;
        width: calc(100% - 180px);
        margin: 0;
        float: right;
        color: #727272!important;
        height: 40px;
        line-height: 40px;
        padding: 0 1.5rem 0 .5rem;
        
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #727272;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #727272;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #727272;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #727272;
        }
    }
    option{
        color: #727272!important;
    }
    input[type=checkbox]{
        float: left;
        margin-left: 10px;
        margin-top: 12px;
        width: 15px;
        height: 15px;
        line-height: 18px;
    }
    input.button{
        @include button;
        background-color: $brand-dark;
        width: 100%;
        margin-top: 15px;
        background: darken($brand, 10%);
        color: #fff!important;
        height: auto;
        line-height: auto;
        &:hover{
            background: darken($brand-dark, 5%);
        }
    }
}

#result{
    .product-card-cont{
        width: 100%;
        margin: 0;
    }
    .product-image{
        img{
            width: 100%;
            height: auto;
        }
    }
    .product-info{
        padding-left: 15px;
        
        @media screen and (max-width: 640px) {
            padding-left: 0;
            padding-top: 15px;
        }
        h3.product-name{
            font-size: 22px;
            margin: 0;
        }
        h4.product-price{
            font-size: 18px;
            margin: 5px 0 10px;
        }
        p{
            line-height: 1.6;
            margin-bottom: 10px;
        }
        .button{
            width: 49%;
        }
        .button + .button{
            margin-left: 2%;
        }
    }
}

.reveal{
    h3{
        text-align: center;
        padding: 25px 0;
        font-size: 24px;
    }
    input[type=checkbox]{
        margin: 0;
        margin-left: 5px;
        vertical-align: middle;
    }
}

#thumbnails{
    position: relative;
    
    .owl-dots{
        text-align: left;
    }
    
    .enlarge{
        position: absolute;
        right: 0;
        bottom: 0;
        i{
            margin-right: 4px;
        }
        p{
            font-size: 12px;
            line-height: 30px;
        }
    }
}

.actions{
    margin-top: 15px;
    .button{
        @include button;
        width: 49%;
        float: left;
        &:hover{
            background: darken($brand, 5%);
        }
    }
    a + a{
        margin-left: 2%;
    }
}

.product-right{
    h3{
        &:first-of-type{
            margin-top: 0;
        }
    }
}

/* ===========================] Media Queries [======================== */


@media screen and (max-width: 1025px) {

    .menu-btn-wrap{
        display: block;
        text-align: right;
    }

    .mobile-menu-wrap{
        .menu.vertical{
            flex-wrap: wrap;
            li{
                flex: 0 0 100%;
            }
        }
        .close-button{
            display: block !important;
        }
    }

    .mobile-menu-wrap{
        position: fixed;
        height: 100%;
        width: 400px;
        right: 0;
        top: 0;
        background: $brand;
        padding: 2em;
        z-index: 9999;
        transform: translateX(100%);
        -webkit-transition: transform cubic-bezier(1,.21,0,1.07) .45s;
        -moz-transition: transform cubic-bezier(1,.21,0,1.07) .45s;
        transition: transform cubic-bezier(1,.21,.26,.98) .45s;
        box-shadow: none;

        ul{
          margin-top: 3em;
          li{
              a{
                  color: #fff;
                  padding: 1em;
              }
          }
        }

        &.is-active{
            transform: translateX(0);
        }

        .close-button{
            padding: 0.5em;
            position: absolute;
            top: 1rem;
            right: 1rem;
            line-height: 21px;
            span{
                color: #fff;
                font-size: 2.5rem;
                font-weight: lighter;
            }
        }
    }

}


@media screen and (max-width: 640px) {

    .mobile-menu-wrap{
        width: 100%;
    }

    .mobile-padding{
        padding: 0% 6%;
    }
}
.enquire-required{
    color: red;
    font-size: 12px;
}
